import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import md5 from 'js-md5'

//导入element ui
import './plugins/element.js'
//导入全局样式
import './assets/css/globe.css'
//导入字体图标

//import '../public/fonts/iconfont.css'

import axios from 'axios'
//导入插件
import TreeTable from 'vue-table-with-tree-grid'


//配置路径
//https://lianghj.top:8888/api/private/v1/
//axios.defaults.baseURL = 'http://192.168.101.105/admin.php/' // 'https://127.0.0.1:8888/api/private/v1/'
Vue.prototype.$http = axios
Vue.prototype.$ip = "https://ledapi.quanminjujian.com/"
Vue.prototype.$baseUrl = Vue.prototype.$ip + "admin.php/"
Vue.prototype.$imgUrl = Vue.prototype.$ip + "storage/"

document.title="LED展示管理系统"
//storage
Vue.use(ElementUI);


// 把字符串中的汉字、非字母数字 s转换成Unicode
function ch2Unicdoe(str) {
	if (!str) {
		return;
	}
	var unicode = '';
	for (var i = 0; i < str.length; i++) {
		var temp = str.charAt(i);
		if (isChinese(temp)) {
			unicode += '\\u' + temp.charCodeAt(0).toString(16);
			//console.log(temp, '\\u' + temp.charCodeAt(0).toString(16));
		} else if (temp.charCodeAt(0) > 127) {
			//210912 新增判断是否为扩展的ansi码 如 °
			unicode += '\\u00' + temp.charCodeAt(0).toString(16);
		} else {
			unicode += temp;
		}
	}
	return unicode;
}
//获取用户信息
function getUserData() {
	let str = window.localStorage.getItem("userInfo")
	let obj = JSON.parse(str)
	console.log(obj,'获取用户信息')
	let retObj = {
		auid: obj && obj.auid ? obj.auid : 0,
		token: obj && obj.token ? obj.token : ''
	}
	return retObj
}
Vue.prototype.$getUserData = getUserData;



// 判断字符是否为汉字，
function isChinese(s) {
	//return /[\u4e00-\u9fa5]/.test(s); //判断字符是否为汉字，
	//[^\x00-\xff] //匹配双字节字符 包括汉字在内：[^\x00-\xff]
	return /[^\x00-\xff]/.test(s);
}

var signTxt = "sfeijwie3mseoimwoerf" //加密

/*获取发送参数*/
function getSendParam(data = []) {
	//计算加密
	let time = Date.parse(new Date()) / 1000;

	let arr = [];
	//let datajson = data ? JSON.stringify(data):[];
	arr['data'] = data ? data : [];

	let obj = getUserData()
	arr['data']['auid'] = obj.auid;//把auid放在data里面

	let str = ch2Unicdoe(JSON.stringify(arr['data']));
	//把/改为 \/ 
	let newstr = str.replace(/\//g, '\\/');
	//console.log(newstr);
	let sign = md5(newstr + '&' + time + '&' + signTxt);
	//转为大写
	sign = sign.toLocaleUpperCase()
	console.log(newstr + '&' + time + '&' + signTxt);
	arr['sign'] = sign;
	arr['time'] = time;
	arr['userid'] = obj.auid;
	arr['token'] = obj.token;


	return arr;
}
Vue.prototype.$getSendParam = getSendParam

//封装POST请求
Vue.prototype.$post = function (method, data = []) {
	var isRet = false
	var isShowDlg = false;

	//1秒后才显示
	setTimeout(function () {
		//1秒未返回才弹出
		if (isRet === false) {
			isShowDlg = true
			//为了用户体验，加一个loading效果

		}

	}, 1000)
	let arr = getSendParam(data)

	let url = this.$baseUrl + method
	return new Promise((resolved, rejected) => {
		console.log(url, arr)
		this.$http({
			method: 'POST',
			url,
			data: {
				...arr
			}
		}).then(res => {
			//console.log("返回数据",res)
			//console.log("statusCode",res.status)
			if (res.status === 200) {
				//判断是否为200业务成功代码
				if (res.data.code !== 200) {
					//console.log("res.data.code",res.data.code)
					this.$err(res.data.msg)
					//检测是否需要登录
					if (res.data.code === 300) {
						console.log(res.data.msg)
						//跳到登录页
						this.$router.push('/login')
					}

				}
				//返回执行数据
				resolved(res.data);
				return
			}
			//出错的代码
			rejected(res)
			return
		}).catch(error => {
			rejected(error)
		})


	})
}
//请求当前拦截
axios.interceptors.request.use(config => {
	console.log(config)
	//config.headers.Authorization = window.localStorage.getItem('token') // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjUwMCwicmlkIjowLCJpYXQiOjE1ODMyODgxNjMsImV4cCI6MTU4MzM3NDU2M30.LBFXgTMuRJ-D3ozdcdhqRQ10Uou91V-Fuk98PoneaPA" 
	//最后必须加  return config
	return config
})


const getData = function (originVai) {
	console.log('getData', originVai)
	//const dt= new Date(parseInt(originVai) * 1000)
	const dt = new Date(parseInt(originVai + '000'))
	const y = dt.getFullYear()
	const m = (dt.getMonth() + 1 + '').padStart(2, '0')
	const d = (dt.getDate() + '').padStart(2, '0')

	const hh = (dt.getHours() + '').padStart(2, '0')
	const mm = (dt.getMinutes() + '').padStart(2, '0')
	const ss = (dt.getSeconds() + '').padStart(2, '0')
	let ret = `${y}-${m}-${d}  ${hh}:${mm}:${ss}`
	// console.log(originVai,ret)
	return ret
}
//获取当前时间
const getNowTime = function () {
	const dt = new Date()
	const y = dt.getFullYear()
	const m = (dt.getMonth() + 1 + '').padStart(2, '0')
	const d = (dt.getDate() + '').padStart(2, '0')

	const hh = (dt.getHours() + '').padStart(2, '0')
	const mm = (dt.getMinutes() + '').padStart(2, '0')
	const ss = (dt.getSeconds() + '').padStart(2, '0')
	let ret = `${y}-${m}-${d}  ${hh}:${mm}:${ss}`
	return ret
}
//全局时间挂在
Vue.filter("dateFormat", getData)
//时间处理挂载
Vue.prototype.$getData = getData
Vue.prototype.$getNowTime = getNowTime
//字符串转10位时间戳

Vue.prototype.$getTime = function (str) {
	//var date="2014-05-08 00:22:11";
	let date = new Date(Date.parse(str.replace(/-/g, "/")));
	date = date.getTime() / 1000;
	return date
}

Vue.prototype.$copy = function (val = '') {
	//var Url2 = document.getElementById("biao1").innerText;
	var oInput = document.createElement('input');
	oInput.value = val;
	document.body.appendChild(oInput);
	oInput.select(); // 选择对象
	document.execCommand("Copy"); // 执行浏览器复制命令
	oInput.className = 'oInput';
	oInput.style.display = 'none';
	this.$ok('复制成功');
}

//补全图片地址
Vue.prototype.$getUrl = function (url) {
	console.log(url);
	if (!url || url.length < 1) return;
	if (url.indexOf("http") != 0) {
		url = this.$imgUrl + url;
	}
	return url;
}

//获取选项值
Vue.prototype.getOptionVal = function (arr = [], val, type = "array") {
	// console.log(val, arr, type);
	// if (!arr || arr.length < 1) return "";
	if (type == "arrayObj") {
		for (let i in arr) {
			if (arr[i].val == val) {
				//console.log("找到", val, arr[i].title);
				return arr[i].title;
			}
		}
		//console.log(val, "未找到");
		return "";
	} else {
		return arr[val];
	}
},

	new Vue({
		router,
		render: h => h(App)
	}).$mount('#app')
