<template>
  <div id="app">
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
.el-message-box__btns {
  display: flex;
  justify-content: flex-end;
}
.caoz {
  display: flex;
  justify-content: space-around;
}

</style>
