<template>
  <div class="showPageDiv">
    <div class="showTitleDiv">
      <div class="showTitleItem" v-for="(item, index) in showTitleArr" :key="index">
        {{ item }}
      </div>
    </div>
    <div class="listDiv">
      <!--
        @mouseenter="stopScroll" @mouseleave="autoScroll
    -->
      <div class="tableDiv">
        <div class="tHeaderContainer">
          <table class="tHeader">
            <thead>
              <tr ref="tr">
                <th v-for="(item, index) in titleArr" :key="index">{{ item }}</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="scrollContainer">
          <!-- 用ref绑定后就不需要再获取dom节点了，直接绑定table和tBody，然后$refs里面调用就行。
        在javascript里面调用：this.$refs.table  这样就可以减少获取dom节点的消耗了 -->
          <table class="tTableName" ref="table">
            <tbody ref="tBody">
              <tr v-for="(item, index) in listData" :key="index">
                <td v-for="(item2, index2) in propArr" :key="index2">{{ item[item2] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
let scrollTimer;
export default {
  name: "",
  components: {},
  data() {
    return {
      showAuid: '',
      showCode: '',
      listData: [],
      showSet: {
        show_speed: 4,
        show_title: ''
      },
      titleArr: [],// ['序号', '企业名称', '职位名称', '薪酬待遇', '工作地点', '招聘人数', '工作性质', '经验要求', '学历要求', '年龄要求', '联系人'],//标题
      propArr: [],
      /*
      [
        'info_client_name',
        'info_job_name',
        'info_wages',
        'info_location',
        'info_need_num',
        'info_work_type',
        'info_jingyan',
        'info_education',
        'info_age',
        'info_contacts'],
        */
      showTitleArr: [],//顶部显示的标题行组
      table_offsetTop: 0,//初始表格顶部
      isScroll: false,//是否在滚动中

    };
  },
  created() {
    console.log(this.$route)
    this.showAuid = (this.$route && this.$route.query && this.$route.query.id) ? this.$route.query.id : ''
    this.showCode = (this.$route && this.$route.query && this.$route.query.code) ? this.$route.query.code : ''

    this.getList()

  },
  mounted() {
    //this.autoScroll();

  },
  methods: {
    //获取数据列表
    async getList() {
      if (!this.showAuid) {
        this.$info('没有用户ID');
        return
      }

      const res = await this.$post("Index/getList", {
        id: this.showAuid,
        code: this.showCode,
      });
      this.listData = res.data['list'] ? this.getInfoMsgByShow(res.data['list']) : []
      this.showSet = res.data['showSet'] || {
        show_speed: 4,
        show_title: ''
      }
      this.showTitleArr = this.showSet.show_title ? this.showSet.show_title.split("\n") : []
      console.log(this.listData)
      console.log(this.showTitleArr)

      this.$nextTick(() => {
        this.autoScroll()
      })

    },
    //把信息内容展示到列
    getInfoMsgByShow(arr = []) {
      //return arr;
      console.log('列表', arr);
      let newArr = []
      if (arr.length < 1) return newArr
      //列
      let jsonData = arr[0]['info_msg']
      this.propArr = ['index', 'online_time']
      this.titleArr = ['序号', '发布时间']
      for (let key in jsonData) {
        //添加到自定义标题列组
        this.titleArr.push(key)
        this.propArr.push(key)
      }

      this.$nextTick(() => {
        console.log(this.$refs.table)
        //this.$refs.table.doLayout()
      })

      console.log("自定义列", this.propArr)
      //展示消息列

      for (let i in arr) {
        // console.log(i, arr[i])
        let jsonData = arr[i]['info_msg']
        // console.log(i, jsonData,)

        let obj = {
          'index': parseInt(i) + 1,
          ...arr[i],
          ...jsonData
        }
        newArr.push(obj)

      }
      return newArr
    },
    autoScroll() {

      //return
      const table = this.$refs.table;
      if (!table || !table.getElementsByTagName("td")[0]) {
        console.log("不存在!")
        return
      }

      console.log("开始滚动")
      //默认未滚动
      this.isScroll = false

      const tBody = this.$refs.tBody;
      //保留这个子节点在原先位置的显示,先用Node.cloneNode方法复制出一个节点的副本,然后再插入到新位置
      //用法：var dupNode = node.cloneNode(deep);node表示将要被克隆的节点，dupNode克隆生成的副本节点
      //deep 可选是否采用深度克隆,如果为true,则该节点的所有后代节点也都会被克隆,如果为false,则只克隆该节点本身
      //appendChild() 方法可向节点的子节点列表的末尾添加新的子节点。

      //table.appendChild(tBody.cloneNode(true));

      //元素的height为在tr中设置的height: 1.8rem，所以offsetHeight始终为 1.8rem
      //(offsetHeight：包括padding、border、水平滚动条，但不包括margin的元素的高度。)

      let speed = table.getElementsByTagName("td")[0].offsetHeight;
      //总行数
      let lineNum = this.listData.length
      let show_speed = this.showSet.show_speed

      let that = this
      function scroll() {
        //console.log("scroll")
        let top = table.offsetTop;

        //offsetTop：DOM对象的上边距离定位父级（最近有定位属性的父级）的上边的距离，也就是table与scrollContainer的距离，初始值为0
        //tBody的offsetHeight是固定值，top表示超出tbody的部分为负数，0.5s循环一次，不断减去speed

        //tBody.offsetHeight
        let newPx = ''
        // console.log(top, speed, -speed * lineNum)
        if (top == 0 || top <= -speed * lineNum) {
          if (that.isScroll == true) {
            //滚动完了
            console.log('滚动完了')
            that.getList()
            clearInterval(scrollTimer)
            return
          }
          newPx = -1 + "px";
          table.style.transition = "0s";
          /*
          table.style.transition = "0s";
          table.style.top = 0;
          */
        } else {
          //表示已在滚动
          that.isScroll = true
          table.style.transition = "linear " + show_speed + "s"; //"ease 0.5s";
          newPx = top - speed + "px";
        }

        table.style.top = newPx
        //console.log('设置px', newPx)

        //clearInterval(scrollTimer)
      }
      scroll()
      scrollTimer = setInterval(scroll, 500);
    },
    stopScroll() {
      clearInterval(scrollTimer);
    },

  },
  computed: {},
};
</script>

<style scoped>
.showPageDiv {
  height: 100vh;
  width: 100%;
  padding: 0 auto 2px auto;
  padding: 0;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  background: #000;
}

.showTitleDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.showTitleItem {
  margin: 0.5rem;
  font-size: 18px;
  font-weight: 400;
}

.listDiv {
  flex: 1;
  width: 100%;
  /* height: 90vh;*/
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0%;
  overflow: hidden;
  /*
  position: relative;
  */
  /* background: url("../showFiles/2.jpg") no-repeat;*/
}

.tableDiv {
  z-index: 100;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  padding: 0;
  box-shadow: 0 0 1rem #b8b7b780 inset;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);

  display: flex;
  flex-direction: column;

}

/*
	.tableDiv::before,
	.tableDiv::after {
		content: "";
		pointer-events: none;
		position: absolute;
		width: 1.5rem;
		height: 1.5rem;
		transition: 0.3s ease-in-out;
	}

	.tableDiv::before {
		top: -0.1rem;
		left: -0.1rem;
		border-top: 0.15rem solid #769ce2;
		border-left: 0.15rem solid #769ce2;
	}

	.tableDiv::after {
		right: -0.1rem;
		bottom: -0.1rem;
		border-bottom: 0.15rem solid #769ce2;
		border-right: 0.15rem solid #769ce2;
	}

	.tableDiv:hover::before {
		width: 100%;
		height: 100%;
		border-top: 0.15rem solid #769ce2;
		border-left: 0.15rem solid #769ce2;
		animation: glow_before 1s ease-out infinite alternate;
	}

	.tableDiv:hover::after {
		width: 100%;
		height: 100%;
		border-bottom: 0.15rem solid #769ce2;
		border-right: 0.15rem solid #769ce2;
		animation: glow_after 1s ease-out infinite alternate;
	}
*/
.tHeaderContainer {
  /*
  position: relative;
  height: 2.7rem;
  */
  width: 100%;
  overflow: hidden;
}

.scrollContainer {
  position: relative;
  /*
 
  height: calc(100% - 2.7rem);
*/
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.tHeader,
.tTableName {
  width: 100%;
  max-height: 98%;
  overflow: hidden;
  /* border-collapse属性是运用在table标签里的， collapse值会让 边框合并为一个单一的边框。会忽略 border-spacing 和 empty-cells 属性。 */
  border-collapse: collapse;
  border-top: 1px solid #FFF;
  border-left: 1px solid #FFF;
}

.tTableName {
  position: absolute;
  background: #000;
  color: #FFF;
}

tr {
  height: 3rem;
  font-size: 0.9rem;
  text-align: center;
  border-bottom: 1px solid rgb(107, 101, 119);

}

/**隔行变色 */
.tTableName tr:nth-child(even) {
  color: red;
}

th {
  font-size: 0.95rem;
  height: 2.5rem;
  text-align: center;
  border-right: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  background: #000;
}

td {

  /*height: 100%;*/
  text-align: center;
  border-right: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
}

th,
td {
  width: 9.6%;
}

th:nth-child(1),
td:nth-child(1) {
  width: 4%;
}

@font-face {
  font-family: "sysFont";
  src: url("/static/font/时尚中黑简体.ttf");
}

@keyframes glow_before {
  0% {
    box-shadow: -0.1rem -0.1rem 0.2rem #408acf50;
  }

  100% {
    box-shadow: -0.5rem -0.5rem 1rem #769ce2;
  }
}

@keyframes glow_after {
  0% {
    box-shadow: 0.1rem 0.1rem 0.2rem #408acf50;
  }

  100% {
    box-shadow: 0.5rem 0.5rem 1rem #769ce2;
  }
}
</style>
