<template>
  <div id="mytable">
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>信息列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :inline="true" class="searchDiv">
      <div class="myBtnDiv">
        <el-form-item label="在线类型">
          <el-radio-group v-model="is_online">
            <el-radio label="">全部</el-radio>
            <el-radio v-for="(item, index) in is_onlineArr" :key="index" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="搜索词">
          <el-input clearable placeholder="输入关键词" v-model="query"></el-input>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="getList()">查询</el-button>
        </el-form-item>

        <el-form-item>
          <el-button type="success" @click="onAdd()">添加</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="onDownXlsx()">下载模板</el-button>
        </el-form-item>
        <el-form-item>
          <el-upload class="upload-demo" style="margin-bottom:0px;" action="https://jsonplaceholder.typicode.com/posts/"
            :multiple="false" :limit="1" :show-file-list="false" accept=".xlsx" :http-request="onImportXlsx"
            ref="refUpload">
            <el-button type="info" @click="onImportXlsx()">导入xlsx</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="this.selectRows.length < 1" type="warning" @click="saveOnLineAll()">批量上线</el-button>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="this.selectRows.length < 1" type="warning" @click="saveDownAll()">批量下架</el-button>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="this.selectRows.length < 1" type="warning" @click="onDelItemAll()">批量删除</el-button>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="this.selectRows.length < 1" type="warning" @click="onEditHourAll()">批量改小时</el-button>
        </el-form-item>


      </div>

    </el-form>

    <!--表格数据区-->
    <el-table v-loading="isLoading" :height="getH()" :border="true" :data="tableData" highlight-current-row strauide
      :resizable="true" @selection-change="handleSelectionChange" ref="table">
      <el-table-column type="selection"> </el-table-column>

      <el-table-column v-for="(item, index) in propArr" :key="index" :prop="item.prop" :label="item.title"
        :min-width="item.width" :data-type="item.type" align="center" strauide show-overflow-tooltauid>
        <template slot="header" slot-scope="headerData">
          <div :title="headerData.column.label">
            {{ getTitleLabel(headerData.column.label) }}
          </div>
        </template>
        <template slot-scope="scope">
          <div v-if="item.type == 'array'">
            {{ getOptionVal(item.data, scope.row[item.prop]) }}
          </div>

          <div v-else class="titleDiv">
            {{ scope.row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <div class="caoz">
            <el-button type="text" @click="onEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="onDelItem(scope.row)">
              删除</el-button>
            <el-button v-if="scope.row['is_online'] == 1" type="text" @click="saveDown(scope.row)"><span
                style="color: red">下架</span></el-button>
            <el-button v-else type="text" @click="saveOnLine(scope.row)"><span style="color: green">上线</span>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--底部分页区-->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
      :page-sizes="[10, 30, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <!--编辑描述窗口-->
    <el-dialog v-if="showEditDlg" :close-on-click-modal="false" :title="!editItem.lid ? '新增' : '编辑'"
      :visible.sync="showEditDlg" width="300" :before-close="handleCloseDlg">
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col class="myElCol" :span="11" v-for="(item, index) in propArr" :key="index">
            <el-form-item :label="item.title">
              <el-select v-if="item.type == 'array'" v-model="editItem[item.prop]" placeholder="请选择">
                <el-option v-for="(item2, index2) in item.data" :key="index2" :label="item2" :value="index2">
                </el-option>
              </el-select>
              <el-date-picker v-else-if="item.type == 'time'" :disabled="item.prop == 'add_time' && !editItem.lid"
                v-model="editItem[item.prop]" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间">
              </el-date-picker>

              <el-input v-else :type="item.type == 'textarea' ? 'textarea' : 'text'" :disabled="item.prop == 'lid'"
                :rows="2" clearable v-model="editItem[item.prop]">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { count } from 'console';


export default {
  data() {
    return {
      //搜索
      auid: "",
      query: "",
      is_online: "",
      //表格字段
      /*
  `lid` int(11) NOT NULL AUTO_INCREMENT,
  `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP ON UPDATE CURRENT_TIMESTAMP COMMENT '添加时间',
  `auid` int(11) NOT NULL DEFAULT '0' COMMENT '添加账号ID',
  `is_online` int(11) NOT NULL DEFAULT '0' COMMENT '是否在线',
  `online_time` timestamp NULL DEFAULT NULL COMMENT '上线时间',
  `show_hour` int(11) NOT NULL DEFAULT '24' COMMENT '显示小时数',
  `info_client_name` varchar(255) NOT NULL DEFAULT '' COMMENT '企业名字',
  `info_job_name` varchar(255) NOT NULL DEFAULT '' COMMENT '职位名字',
  `info_wages` varchar(255) NOT NULL DEFAULT '' COMMENT '薪酬待遇',
  `info_location` varchar(255) NOT NULL DEFAULT '' COMMENT '工作地点',
  `info_need_num` varchar(255) NOT NULL DEFAULT '' COMMENT '招聘人数',
  `info_work_type` varchar(255) NOT NULL DEFAULT '' COMMENT '工作性质',
  `info_jingyan` varchar(255) NOT NULL DEFAULT '' COMMENT '经验要求',
  `info_education` varchar(255) NOT NULL COMMENT '学历要求',
  `info_age` varchar(255) NOT NULL DEFAULT '' COMMENT '年龄要求',
  `info_contacts` varchar(255) NOT NULL DEFAULT '' COMMENT '联系人',
    `bz` varchar(255) NOT NULL DEFAULT '' COMMENT '备注',
  PRIMARY KEY (`lid`)
      */
      is_onlineArr: ["下架", "在线"],
      propArr: [],
      propArr_back: [
        {
          prop: "lid",
          title: "ID",
          width: 100,
          type: "",
        },

        {
          prop: "add_time",
          title: "添加时间",
          width: 180,
          type: "time",
        },

        {
          prop: "is_online",
          title: "是否在线",
          width: 150,
          type: "array",
          data: ["下架", "在线"],
        },
        {
          prop: "online_time",
          title: "上线时间",
          width: 180,
          type: "time",
        },
        {
          prop: "show_hour",
          title: "显示小时数",
          width: 150,
          type: "",
        },
        /*
        {
          prop: "info_msg",
          title: "信息内容",
          width: 350,
          type: "",
        },
        */
        {
          prop: "bz",
          title: "备注",
          width: 150,
          type: "textarea",
        },
      ],
      //表格数据
      tableData: [],
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //屏幕高度 document.body.clientHeight
      screenHeight: 0,
      //是否获取字段
      isGetFeild: false,
      //正在加载
      isLoading: false,
      //当前编辑项目内容
      editItem: {},
      //显示编辑窗口
      showEditDlg: false,
      //当前选择的行
      selectRows: [],

      //自定义列的标题
      myColumnArr: [],
    };
  },
  created() {
    console.log(" 创建");

    this.initArr();
    this.getList();

    this.initEdit();
  },
  components: {
  },
  mounted() { },
  watch: {
    is_online(val) {
      this.getList();
    },
  },
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 250;
    },
    initArr() {
      //初始化选项值
      for (let i in this.propArr) {
        if (this.propArr[i]["prop"] == "msg_type") {
          this.propArr[i]["data"] = this.msg_typeArr;
        }
        if (this.propArr[i]["prop"] == "is_read") {
          this.propArr[i]["data"] = this.is_readArr;
        }
      }
    },
    //初始化编辑项目为空
    initEdit() {
      for (let i in this.propArr) {
        this.$set(this.editItem, this.propArr[i]["prop"], "");
      }
      console.log(this.editItem);
    },
    //获取表数据
    async getList(page = 1) {
      const postData = {
        auid: this.auid,
        query: this.query,
        is_online: this.is_online,
        page,
        pageSize: this.pageSize,
      };
      this.isLoading = true;
      const res = await this.$post("InfoList/getList", postData);

      let ret = res.data || [];

      this.tableData = ret.data ? this.getInfoMsg(ret.data, page) : [];
      this.page = ret.current_page;
      this.last_page = ret.last_page;
      this.total = ret.total;

      console.log(this.tableData);

      this.isLoading = false;
    },
    //把信息内容展示到列
    getInfoMsg(arr = [], page) {
      //return arr;
      console.log('列表', arr);
      let newArr = []
      if (arr.length < 1) return newArr
      //是否已添加了列
      if (page == 1) {
        let oldProp = JSON.parse(JSON.stringify(this.propArr_back))
        let jsonData = arr[0]['info_msg']
        this.myColumnArr = []
        for (let key in jsonData) {

          let obj = {
            prop: key,
            title: key,
            width: 200,
            type: "",
          }
          //添加到自定义标题列组
          this.myColumnArr.push(key)
          //添加到倒数第二列
          oldProp.splice(oldProp.length - 1, 0, obj)
        }
        this.propArr = oldProp
        this.$nextTick(() => {
          this.$refs.table.doLayout()
        })

        console.log("自定义列", this.propArr)
      }
      //展示消息列
      for (let i in arr) {
        // console.log(i, arr[i])
        let jsonData = arr[i]['info_msg']
        // console.log(i, jsonData,)

        let obj = {
          ...arr[i],
          ...jsonData
        }
        newArr.push(obj)

      }
      console.log('展示后', newArr)
      return newArr
    },
    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getList(1);
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page = newPage;
      this.getList(this.page);
    },
    //取标题，有括号的去除
    getTitleLabel(val) {
      //console.log(val)
      if (!val) return "";
      let index = val.lastIndexOf("(");
      if (index > -1) {
        val = val.substring(0, index);
      }
      return val;
    },
    //编辑内容
    onEdit(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },
    //添加内容
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      obj["add_time"] = this.$getNowTime();
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击确定保存修改用户信息
    async onSaveEdit() {
      let newData = JSON.parse(JSON.stringify(this.editItem))
      //把自定义字段的内容，合并，并删除此字段
      let info_msg = {}
      for (let i in this.myColumnArr) {
        let key = this.myColumnArr[i]
        console.log(i, newData[key])
        info_msg[key] = newData[key]
        delete newData[key]
      }
      newData['info_msg'] = info_msg
      let obj = {
        lid: this.editItem.lid,
        data: newData,
      };

      const res = await this.$post(`InfoList/saveData`, obj);
      //console.log(res);
      this.showEditDlg = false;
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
        "此操作将永久删除该信息?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        lid: item.lid,
      };
      console.log(obj2);
      const res = await this.$post(`InfoList/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //下架
    async saveDown(item) {
      let obj = {
        lid: item.lid,
      };
      console.log(obj);
      const res = await this.$post(`InfoList/saveDown`, obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //上线
    async saveOnLine(item) {
      let obj = {
        lid: item.lid,
      };
      console.log(obj);
      const res = await this.$post(`InfoList/saveOnLine`, obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },

    //批量上线
    async saveOnLineAll() {
      if (this.selectRows.length < 1) {
        return this.$message.info("没有勾选");
      }
      //弹出对话框
      const confirmResult = await this.$confirm("确认批量上线勾选行?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消");
      }

      let lidarr = [];
      for (let i in this.selectRows) {
        lidarr.push(this.selectRows[i]["lid"]);
      }

      console.log(lidarr);
      let obj = {
        lidStr: lidarr.join(","),
      };
      const res = await this.$post(`InfoList/saveOnLineAll`, obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },


    //批量下架
    async saveDownAll() {
      if (this.selectRows.length < 1) {
        return this.$message.info("没有勾选");
      }
      //弹出对话框
      const confirmResult = await this.$confirm("确认批量下架勾选行?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消");
      }

      let lidarr = [];
      for (let i in this.selectRows) {
        lidarr.push(this.selectRows[i]["lid"]);
      }

      console.log(lidarr);
      let obj = {
        lidStr: lidarr.join(","),
      };
      const res = await this.$post(`InfoList/saveDownAll`, obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },

    //批量删除
    async onDelItemAll() {
      if (this.selectRows.length < 1) {
        return this.$message.info("没有勾选");
      }
      //弹出对话框
      const confirmResult = await this.$confirm("确认批量删除勾选行?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消");
      }

      let lidarr = [];
      for (let i in this.selectRows) {
        lidarr.push(this.selectRows[i]["lid"]);
      }

      console.log(lidarr);
      let obj = {
        lidStr: lidarr.join(","),
      };
      const res = await this.$post(`InfoList/delItemAll`, obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //批量改小时
    async onEditHourAll() {
      if (this.selectRows.length < 1) {
        return this.$message.info("没有勾选");
      }
      //弹出输入框
      const ret = await this.$prompt('请输入小时数', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\d+/,
        inputErrorMessage: '小时数不正确'
      }).catch((err) => err);

      console.log(ret)
      if (ret == "cancel") {
        return this.$info("已取消");
      }

      let show_hour = ret.value
      console.log(show_hour)
      if (parseInt(show_hour) < 1) {
        return this.$info("小时数不正确")
      }

      let lidarr = [];
      for (let i in this.selectRows) {
        lidarr.push(this.selectRows[i]["lid"]);
      }

      console.log(lidarr);
      let obj = {
        lidStr: lidarr.join(","),
        show_hour
      };
      const res = await this.$post(`InfoList/saveHourAll`, obj);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    handleCloseDlg() {
      this.showEditDlg = false;
    },
    //选择行改变
    handleSelectionChange(val) {
      this.selectRows = val;
      console.log(this.selectRows);
    },

    //excel 批量导入
    onImportXlsx(params) {
      //清空文件
      this.$refs['refUpload'].clearFiles()
      if (!params) return;
      console.log(params)
      let url = this.$baseUrl + 'InfoList/upXlsx'
      let arr = this.$getSendParam()
      console.log('arr', arr)
      let fd = new FormData()
      fd.append('xlsx', params.file)
      for (let index in arr) {
        //console.log(index, arr[index])
        fd.append(index, arr[index])
      }
      //把auid带上
      fd.append('auid', arr['userid'])
      //禁用表格
      this.isLoading = true

      let that = this
      this.$http({
        method: 'POST',
        url,
        data: fd,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(ret => {
        that.isLoading = false
        let res = ret['data']
        console.log(res)
        that.$ok(res.msg)
        //重新读取列表
        that.getList()

      }).catch(error => {
        that.isLoading = false
        console.log(error.message)
        that.$info(error.message)
      })


    },
    //下载xlsx模板
    onDownXlsx() {
      window.open('./导入展示项目模板.xlsx')
    }

  },
};
</script>

<style lang="less">
.myFormDiv {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
<style lang="less" scoped>
.titleDiv {
  overflow: hidden;
  text-overflow: ellauidsis;
  white-space: nowrap;
}

.searchDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .myBtnDiv {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.myFormDiv {
  height: 100vh;
  overflow-y: scroll;
}

.myElCol {
  padding: 10px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.checkItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div:first-child {
    width: 100px;
  }
}
</style>
