import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import NoFind from '@/views/NoFind.vue'
import ShowPage from '@/views/ShowPage.vue'
//动态加载组件
const requireComponent = require.context(
	// 其组件目录的相对路径
	'../views',
	// 是否查询其子目录
	true,
	// 匹配基础组件文件名的正则表达式
	/\.vue$/
)
//根目录下的组件
let indexArr = ['Login', 'Home', 'NoFind', 'ShowPage']
//Home下的children组件
let childrenArr = [
	{
		path: '/NoFind',
		component: NoFind
	},

]

requireComponent.keys().forEach(fileName => {
	// 获取组件配置
	const componentConfig = requireComponent(fileName)
	// // 获取和目录深度无关的文件名
	const componentName = fileName.split('/').pop().replace(/\.\w+$/, '')
	//console.log(componentName)
	if (indexArr.indexOf(componentName) > -1) return
	//添加到数组
	childrenArr.push({
		path: '/' + componentName,
		component: componentConfig.default || componentConfig
	})

})

Vue.use(Router)
//初始化非Home路由
let routes = [
	{
		path: '/',
		redirect: '/Login',
	},
	{
		path: '/Login',
		component: Login
	},
	{
		path: '/ShowPage',
		component: ShowPage
	},

]
//添加home路由
routes.push({
	path: '/Home',
	component: Home,
	children: childrenArr,
	redirect: '/infoList' //自动跳到列表页
})


console.log('所有路由', routes)
const router = new Router({
	routes
})

//路由卫士
router.beforeEach((to, from, next) => {
	console.log('to.path=' + to.path, to.matched)
	if (to.path == '/NoFind') {
		next()
	} else {
		//是否有匹配的
		if (to.matched.length < 1) {
			console.log('无组件:', to, to.path)
			next('/NoFind')
		} else {
			next()
		}
	}
})

export default router;