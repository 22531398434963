<template>
  <div id="mytable">
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>展示设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="" style="height: 10px"></div>

    <el-form :inline="true" class="searchDiv">
      <div>
        <el-form-item>
          <el-button type="primary" @click="getSetData()">刷新</el-button>
        </el-form-item>
        <el-form-item style="margin-left: 40px">
          <el-button type="success" @click="saveSetData()">保存</el-button>
        </el-form-item>


      </div>


      <el-form-item label="显示标题" label-width="120px">
        <el-input type="textarea" style="width: 500px" :rows="4" clearable placeholder="显示标题"
          v-model="setDdata.show_title"></el-input>
      </el-form-item>


      <el-form-item label="默认显示速度(秒)" label-width="120px">
        <el-input clearable placeholder="默认显示速度" v-model="setDdata.show_speed"></el-input>
      </el-form-item>


      <el-form-item label="展示密码" label-width="120px">
        <el-input clearable placeholder="展示密码防止别人查看" v-model="setDdata.show_code"></el-input>
      </el-form-item>

      <el-form-item style="margin-left: 0px">
        <el-button type="info" @click="onToShowPage()">跳到显示页</el-button>
        <div style="color: red; font-size:14px; font-weight:bold">显示页按F11全屏</div>
      </el-form-item>

    </el-form>

  </div>
</template>

<script>
export default {
  data() {
    return {
      setDdata: {
        show_title: '',
        show_speed: 4,
        show_code: '',
      },
      //屏幕高度 document.body.clientHeight
      screenHeight: 0,

    };
  },
  created() {
    console.log(" 创建");
    this.getSetData();
  },

  mounted() {

  },
  computed: {

  },
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 300;
    },

    //获取表数据
    async getSetData() {
      const postData = {
        auid: this.auid
      };
      this.isLoading = true;
      const res = await this.$post("ShowSet/getShowSet", postData);
      this.setDdata = res.data || []
      this.isLoading = false;
    },
    //保存
    async saveSetData() {
      const res = await this.$post(`ShowSet/saveShowSet`, this.setDdata);
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.getSetData();
    },
    //跳到展示页
    onToShowPage() {
      let userinfo = this.$getUserData();
      let routeUrl = this.$router.resolve({
        path: "/ShowPage",
        query: {
          id: userinfo['auid'],
          code: this.setDdata.show_code
        }
      });
      window.open(routeUrl.href, '_blank');
    }
  },
};
</script>

<style lang="less">
.myFormDiv {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
<style lang="less" scoped>
.titleDiv {
  overflow: hidden;
  text-overflow: ellauidsis;
  white-space: nowrap;
}

.searchDiv {
  display: flex;
  align-items: left;
  flex-direction: column;
}

.myFormDiv {
  height: 100vh;
  overflow-y: scroll;
}

.myElCol {
  padding: 10px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.checkItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div:first-child {
    width: 100px;
  }
}
</style>
