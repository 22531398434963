<template>
  <el-container class="home-container">
    <!--头部区域-->
    <el-header>
      <div>
        <img class="tbl" src="../assets/logo.png" alt="" style="height: 40px; background-color: #373d41" />
        <span>LED展示管理系统</span>
      </div>
      <el-button type="info" @click="logout">退出</el-button>
    </el-header>

    <!--主体区域-->
    <el-container style="height: 350px">
      <!--左侧边栏-->
      <el-aside :width="iscollapse ? '69px' : '240px'">
        <div class="toggle-button" @click="togglecollapse">|||</div>
        <!--搜索区域-->
        <div class="searchDiv">
          <el-input v-model="searchMenu" size="mini" clearable placeholder="搜索菜单">></el-input>
        </div>
        <el-menu background-color="#333744" text-color="#fff" active-text-color="#409eff" :unique-opened="true"
          :collapse="iscollapse" :collapse-transition="true" router :default-active="activepath" @open="onMenuOpen">
          <!--一级菜单
           :index="item.authName"
          -->
          <el-submenu :index="item.authName" v-for="(item, index_sub) in menulist" :key="index_sub">
            <template slot="title">
              <!--图标-->
              <i :class="item.icon"></i>
              <!--文本-->
              <span>{{ item.authName }}</span>
            </template>
            <!--二级菜单-->
            <el-menu-item :index="'/' + subItem.path" v-for="(subItem, index2) in item.children"
              :key="index2 + index_sub * 100" @click="saveNavstats('/' + subItem.path)">
              <template slot="title">
                <!--图标-->
                <i class="el-icon-menu"></i>
                <!--文本-->
                <span>{{ subItem.authName }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!--右侧边栏主体-->
      <el-main>
        <!--路由站位符-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      //搜索菜单功能
      searchMenu: "",
      //左侧菜单数据
      menulistBack: [
        {
          authName: "信息管理",
          icon: "el-icon-s-tools",
          children: [
            {
              authName: "信息列表",
              path: "infoList",
            },
            {
              authName: "展示设置",
              path: "showSet",
            },

          ],
        },
      ],
      menulist: [],
      //是否折叠
      iscollapse: false,
      //被激活的地址
      activepath: "",
    };
  },
  created() {
    this.activepath ='/infoList' //window.sessionStorage.getItem("activepath");
    console.log("created", this.activepath);

    //this.getPowerList();
    //给菜单加索引
    /*
    for (let i in this.menulistBack) {
      //一级菜单
      this.$set(this.menulistBack[i], "index", i);

      for (let j in this.menulistBack[i]["children"]) {
        //二级菜单
        this.$set(this.menulistBack[i]["children"][j], "index", (i*1 * 100 + j*1)+'');
      }
    }
     console.log('菜单项')
    console.log(this.menulistBack)
    */
    //初始化菜单
    this.menulist = JSON.parse(JSON.stringify(this.menulistBack));
    // this.initMenulist();
    //默认进入列表页
    //this.$router.push('/infoList')
  },
  watch: {
    //搜索词改变
    searchMenu(val) {
      console.log(val);
      if (!val || val.length < 1) {
        this.menulist = JSON.parse(JSON.stringify(this.menulistBack));
        return;
      } else {
        this.menulist = [];
        for (let i in this.menulistBack) {
          //查找子项
          let childrenArr = [];
          for (let j in this.menulistBack[i]["children"]) {
            if (
              this.menulistBack[i]["children"][j]["authName"].indexOf(val) > -1
            ) {
              childrenArr.push(
                JSON.parse(JSON.stringify(this.menulistBack[i]["children"][j]))
              );
            }
          }
          if (childrenArr.length > 0) {
            //添加一级
            let obj = JSON.parse(JSON.stringify(this.menulistBack[i]));
            obj["children"] = childrenArr;
            this.menulist.push(obj);
          }
        }
      }
    },
  },
  methods: {
    //初始化菜单
    initMenulist() {
      //模块名称与类列表独立保存
      let power = window.localStorage.getItem("power");
      //window.localStorage.setItem("classNameList", res.data["classNameList"]);
      console.log(power);
      let powerList = power.split(",");
      console.log(powerList);

      //只显示有权限的模块
      this.menulist = [];
      for (let i in this.menulistBack) {
        let mainAuthName = this.menulistBack[i]["authName"];
        if (powerList.indexOf(mainAuthName) > -1) {
          this.menulist.push(this.menulistBack[i]);
        }
      }
    },
    getPowerList() {
      console.log("-----权限列表--开始----");
      let arr = [];
      let str = "";
      for (let i in this.menulistBack) {
        let mainAuthName = this.menulistBack[i]["authName"];

        let childrenList = this.menulistBack[i]["children"];
        //console.log(i, authName, childrenList);

        for (let j in childrenList) {
          //console.log(j, childrenList[j])
          let obj = childrenList[j];

          console.log(obj);
          arr.push({
            ...obj,
            mainAuthName,
          });
          str += mainAuthName + "|" + obj.authName + "|" + obj.path + "\r\n";
        }
      }
      console.log("-----权限列表----");
      console.log(arr);
      console.log(str);
    },
    logout() {
      window.localStorage.clear();
      this.$router.push("/");
    },
    //点击按扭 ，切换菜单展开和折叠
    togglecollapse() {
      this.iscollapse = !this.iscollapse;
    },
    //保存连接的激活状态
    saveNavstats(activepath) {
      window.sessionStorage.setItem("activepath", activepath);
      this.activepath = activepath;
      console.log("连接", activepath);
    },
    //sub-menu 展开的回调
    //index: 打开的 sub-menu 的 index， indexPath: 打开的 sub-menu 的 index path
    onMenuOpen(index, indexPath) {
      console.log("菜单选中", index, indexPath);
      this.saveNavstats(index);
    },
  },
};
</script>
<style lang="less">
.el-header {
  height: 30px !important;
}

.el-button {
  display: flex !important;
  height: 30px !important;
  align-items: center !important;
}
</style>
<style lang="less" scoped>
.home-container {
  height: 100%;
}

.el-header {
  //height: 30px;
  background-color: #373d41;
  display: flex; //新式布局
  justify-content: space-between; //左右靠边
  padding-left: 0;
  align-items: center; //纵向居中对齐
  color: #ffff;
  font-size: 18px;

  >div {
    display: flex;
    align-items: center;

    span {
      margin-left: 15px;
    }
  }
}

.toggle-button {
  background-color: #4a5064;
  height: 20px;
  text-align: center;
  font-size: 16px; //字体大小
  line-height: 20px; //居中对齐
  color: #ffff;
  letter-spacing: 0.2em; //字体宽度
  cursor: pointer; //鼠标放上去变小手
}

.el-aside {
  background-color: #333744;

  .el-menu {
    border-right: none;
  }
}

.iconsObj {
  margin-right: 10px;
}

.tbl {
  background-color: #333744;
}

.searchDiv {
  margin: 10px 5px;
}
</style>
